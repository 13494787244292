import CryptoJS from "crypto-js";

const secretKey = process.env.VUE_APP_SECRET_KEY; // Access secret key from .env.local

export default {
    namespaced: true,
    state: {
        encryptedData: null,
        decryptedData: null,
    },
    mutations: {
        SET_ENCRYPTED_DATA(state, data) {
            state.encryptedData = data;
        },
        SET_DECRYPTED_DATA(state, data) {
            state.decryptedData = data;
        },
    },
    actions: {
        encryptCredentials({ commit }, credentials) {
            try {
                const encryptedData = CryptoJS.AES.encrypt(
                    JSON.stringify(credentials),
                    secretKey
                ).toString();
                commit("SET_ENCRYPTED_DATA", encryptedData);
                return encodeURIComponent(encryptedData);
            } catch (error) {
                console.error("Encryption error:", error);
                return null;
            }
        },
        decryptCredentials({ commit }, encryptedData) {
            try {
                const decryptedBytes = CryptoJS.AES.decrypt(
                    decodeURIComponent(encryptedData),
                    secretKey
                );
                const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
                commit("SET_DECRYPTED_DATA", decryptedData);
                return decryptedData;
            } catch (error) {
                console.error("Decryption error:", error);
                return null;
            }
        },
    },
    getters: {
        getEncryptedData: (state) => state.encryptedData,
        getDecryptedData: (state) => state.decryptedData,
    },
};
