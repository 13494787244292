import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import Layout from "../components/layout/LayoutV2.vue";
import store from "../store/index"

Vue.use(VueRouter)

const routes = [
    {
        path: '/signin',
        name: 'login',
        component: LoginView
    },
    {
        path: '/OTP',
        name: 'OTP',
        props: (route) => ({ formData: route.query }),
        component: () => import(/* webpackChunkName: "user-otp" */ '../views/otp.vue')
    },
    {
        path: '/ResetView',
        name: 'ResetView',
        props: (route) => ({ formData: route.query }),
        component: () => import(/* webpackChunkName: "user-otp" */ '../views/ResetView.vue')
    },
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: '/user-dashboard',
                name: 'user-dashboard',
                component: () => import(/* webpackChunkName: "user-dashboard" */ '../views/tickets/TicketListView.vue')
            },

            {
                path: '/ImportStore/:mainCustomerID',
                name: 'ImportStore',
                component: () => import(/* webpackChunkName: "ImportStore" */ '../views/customer/Stores/ImportStore')
            },
            {
                path: '/StoreInfo/:mainCustomerID',
                name: 'StoreInfo',
                component: () => import(/* webpackChunkName: "StoreInfo" */ '../views/customer/Stores/StoreInfo')
            },

            {
                path: '/Pendinginstallations',
                name: 'Pendinginstallations',
                component: () => import(/* webpackChunkName: "create-ticket" */ '../views/Admin/Pendinginstallations')
            },
            {
                path: '/RequestFunds',
                name: 'RequestFunds',
                component: () => import(/* webpackChunkName: "create-ticket" */ '../views/users/RequestFunds')
            },
            {
                path: '/create-ticket',
                name: 'create-ticket',
                component: () => import(/* webpackChunkName: "create-ticket" */ '../views/tickets/view/AddEditTicketView')
            },


            {
                path:'/online-checker',
                name: 'online-checker',
                component: () => import(/* webpackChunkName: "online-checker" */ '../views/tickets/view/onlineChecker.vue')

            },

            {
                path: '/ticket/edit/:ticketId',
                name: 'edit-ticket',
                component: () => import(/* webpackChunkName: "edit-ticket" */ '../views/tickets/view/AddEditTicketView')
            },

            {
                path: '/PendingEvents',
                name: 'PendingEvents',
                component: () => import(/* webpackChunkName: "search-ticket" */ '../views/tickets/view/PendingEvents')
            },
            {
                path: '/FollowUp',
                name: 'FollowUp',
                component: () => import(/* webpackChunkName: "FollowUp" */ '../views/tickets/view/FollowUp')
            },
            {
                path: '/PendingStock',
                name: 'PendingStock',
                component: () => import(/* webpackChunkName: "search-ticket" */ '../views/tickets/view/ReceivedStock.vue')
            },
            {
                path: '/JobCardManagement',
                name: 'JobCardManagement',
                component: () => import(/* webpackChunkName: "JobCardManagement" */ '../views/tickets/view/JobCardManagement')
            },
            {
                path: '/AvailableStock',
                name: 'AvailableStock',
                component: () => import(/* webpackChunkName: "AvailableStock" */ '../views/tickets/view/AvailableStock')
            },
            {
                path: '/request-details/:id',
                name: 'RequestDetails',
                component: () => import(/* webpackChunkName: "RequestDetails" */ '../views/tickets/view/RequestDetails'),
                props: true
            },
            {
                path: '/ticket/search',
                name: 'search-ticket',
                component: () => import(/* webpackChunkName: "search-ticket" */ '../views/tickets/view/TicketSearch')
            },
            {
                path: '/ticket/:ticketId',
                props: true,
                name: 'view-ticket',
                component: () => import(/* webpackChunkName: "view-ticket" */ '../views/tickets/view/TicketDetailsView')
            }, {
                path: 'ticket/:ticket/task',
                props: true,
                name: 'add-ticket-task',
                component: () => import(/*webpackChunkName: "add-task"*/'../views/tickets/tasks/TicketTaskView')
            },
            {
                path: 'ticket/:ticket/task/:task',
                props: true,
                name: 'edit-ticket-task',
                component: () => import(/*webpackChunkName: "edit-task"*/'../views/tickets/tasks/TicketTaskView')
            },
            {
                path: '/customer-list',
                name: 'customer-list',
                component: () => import(/* webpackChunkName: "customer-list" */ '../views/customer/CustomerList')
            },
            {
                path: '/view-ads',
                name: 'view-ads',
                component: () => import(/* webpackChunkName: "customer-list" */ '../views/customer/ViewAds')
            },
            {
                path: 'view-leads',
                name: 'view-leads',
                component: () => import(/* webpackChunkName: "customer-list" */ '../views/customer/ViewLeads')
            },
            {
                path: '/add-store/:mainCustomerID',
                name: 'add-store',
                component: () => import(/* webpackChunkName: "add-store" */ '../views/customer/Stores/CreateStore')
            },
            {
                path: '/StoreData/:mainCustomerID',
                name: 'StoreData',
                component: () => import(/* webpackChunkName: "StoreData" */ '../views/customer/Stores/StoreData')
            },
            {
                path: '/importStoreData/:mainCustomerID',
                name: 'importStoreData',
                component: () => import(/* webpackChunkName: "StoreData" */ '../views/customer/Stores/importStoreData')
            },


            {
                path: '/selectStoreData/:mainCustomerID',
                name: 'selectStoreData',
                component: () => import(/* webpackChunkName: "StoreData" */ '../views/customer/Stores/selectStoreData')
            },
            {
                path: '/view-customer/:mainCustomerID',
                name: 'view-customer',
                component: () => import(/* webpackChunkName: "view-customer" */ '../views/customer/ViewCustomer')
            },
            {
                path: '/email-setup/:mainCustomerID',
                name: 'email-setup',
                component: () => import(/* webpackChunkName: "email-setup" */ '../views/customer/Stores/EmailSetupView')
            },
            {
                path: '/view-store/:storeID',
                name: 'view-store',
                component: () => import(/* webpackChunkName: "view-store" */ '../views/customer/Stores/ViewStoreTickets')
            },
            {
                path: '/select-location',
                name: 'select-location',
                component: () => import(/* webpackChunkName: "select-location" */ '../views/location/SelectLocationView')
            },
            {
                path: '/create-user',
                name: 'create-user',
                component: () => import(/* webpackChunkName: "create-user" */ '../views/users/CreateUpdateUserView')
            },
            {
                path: '/leave',
                name: 'request-leave',
                component: () => import(/* webpackChunkName: "create-user" */ '../views/users/RequestLeave.vue')
            },
            {
                path: '/view-leave',
                name: 'view-leave',
                component: () => import(/* webpackChunkName: "create-user" */ '../views/users/ViewLeaveRequests.vue')
            },
            {
                path: '/displinary',
                name: 'ViewDisplinary',
                component: () => import(/* webpackChunkName: "create-user" */ '../views/users/ViewDisplinary.vue')
            },
            {
                path: '/update-user/:id',
                name: 'update-user',
                component: () => import(/* webpackChunkName: "update-user" */ '../views/users/CreateUpdateUserView')
            },
            {
                path: '/view-user/:id',
                name: 'view-user',
                component: () => import(/* webpackChunkName: "view-user" */ '../views/users/ViewUserData')
            },
            {
                path: '/details/:id',
                name: 'details',
                component: () => import(/* webpackChunkName: "view-user" */ '../views/users/DetailsView.vue')
            },
            {
                path: '/users',
                name: 'users',
                component: () => import(/* webpackChunkName: "users" */ '../views/users/UsersView')
            },
            {
                path: '/BugReports',
                name: 'BugReports',
                component: () => import(/* webpackChunkName: "users" */ '../views/Reports/BugReports')
            },
            {
                path: '/Report',
                name: 'Report',
                component: () => import(/* webpackChunkName: "users" */ '../views/Reports/Report')
            },

            {
                path: '/calendar',
                name: 'calendar',
                component: () => import(/* webpackChunkName: "calendar" */ '../views/calendar/CalendarView')
            },
            {
                path: '/smart-invoice-product-list',
                name: 'smart-invoice-product-list',
                component: () => import(/* webpackChunkName: "smart-invoice-product-list" */ '../views/SmartInvoice/SIProductSelect')
            },
            {
                path: '/view-smart-invoice-signup',
                name: 'view-smart-invoice-signup',
                component: () => import(/* webpackChunkName: "view-smart-invoice-signup" */ '../views/SmartInvoice/ViewSmartInvoiceSignup')
            },
            {
                path: '/chrilan-smart-invoice',
                name: 'chrilan-smart-invoice',
                component: () => import(/* webpackChunkName: "chrilan-smart-invoice" */ '../views/SmartInvoice/Lyt-SI-Signup')
            },
            {
                path: '/lytsoft-smart-invoice',
                name: 'lytsoft-smart-invoice',
                component: () => import(/* webpackChunkName: "lytsoft-smart-invoice" */ '../views/SmartInvoice/LytSoft-Signup')
            },
            {
                path: '/view-lytsoft-smart-invoice',
                name: 'view-lytsoft-smart-invoice',
                component: () => import(/* webpackChunkName: "view-lytsoft-smart-invoice" */ '../views/SmartInvoice/ViewLytSoftSmartInvoiceSignup')
            },
            {
                path: '/performance',
                name: 'performance',
                component: () => import(/* webpackChunkName: "performance" */ '../views/tickets/UserPerformanceStats.vue')
            },
            {
                path: '/DetailsPage/:clientID',
                name: 'DetailsPage',
                component: () => import(/* webpackChunkName: "DetailsPage" */ '../components/DetailsPage.vue')
            },
            {
                path: '/App',
                name: 'appMarket',
                component: () => import(/* webpackChunkName: "DetailsPage" */ '../views/AppMarket.vue')
            },
        ],
    },
]

const router = new VueRouter({
    mode: 'history',
    base: '',
    routes
})
router.beforeEach(async (to, from, next) => {
    if (to.query.userInfo) {
        let userInfo = JSON.parse(window.atob(to.query.userInfo));
        return store.dispatch('userLogin', userInfo);
    } else if (to.name !== 'login' && to.name !== 'OTP') {
        let res = await authenticator.isUserLoggedIn()
        if (!res) {
            store.commit(`setAttemptedRoute`, to);
            next({name: 'login'});
        } else if (res && to.path === `/`) {
            store.commit(`setAttemptedRoute`, to);
            next({ name: 'login' })
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router
