<template>
  <div class="home">
    <div class="banner"></div>
    <LoginForm/>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginForm from '@/components/LoginForm.vue'

export default {
  name: 'HomeView',
  beforeMount() {
    this.$utils.showLoading()
    return this.$store.dispatch('getPreLoginData')
        .finally(()=>this.$utils.hideLoading())
  },
  components: {
    LoginForm,


  }
}
</script>
<style scoped lang="scss">
.home{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

</style>
