import store from '../store/index'
export const responses = {
    throwErr(err,title = `Oops`){
        return Swal.fire({
            title: title,
            icon: `warning`,
            html : `<span>${err}</span>`,
            showCloseButton : true,
        })
    },

    async deviceErr (err) {
        await store.dispatch('fetchResetCount')
        let count = store.getters.getResetCount;
        console.log(count)
        return Swal.fire({
            title: `Registration Error`,
            icon: 'error',
            html: `
            <span>${err}</span><br/>
            <br/>
            <span>Number Of Reset Count(s): ${count.resets}</span><br/>
            <span>Remaining Resets Count(s): ${count.remainingResets}</span>
        `,
            showCloseButton: true,
            confirmButtonText: 'Reset Device',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Enter Your Name",
                    input: "text",
                    showCancelButton: true,
                    confirmButtonText: 'Reset',
                }).then((nameResult) => {
                    if (nameResult.value) {
                        store.dispatch('ResetDevices', nameResult.value)
                            .then(() => {
                                Swal.fire({
                                    title: "Devices Reset",
                                    icon: "success",
                                    text: "Your license have been reset successfully!. Please login again",
                                });
                            })
                            .catch((err) => {
                                Swal.fire({
                                    title: "Error",
                                    icon: "error",
                                    text: `An error occurred while resetting the devices: ${err.message}`,
                                });
                            });
                    }
                });
            }
        });
    },


    showSuccess(msg){
        return Toast.fire({
            icon: 'success',
            title: msg
        });
    },

    showInfo(msg){
        return Toast.fire({
            icon: 'info',
            title: msg
        });
    },

    showWarning(msg){
        return Toast.fire({
            icon: 'warning',
            title: msg
        });
    },
}

