import axios from "axios";

export default {
    name: 'stock',
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        getDispatchPending(context,{status, date,invoice}) {
            if(!status){
                status = 'NEW'
            }
            if(!date){
                date = 'date'
            }
            return axios.get(`${window.synergy3}api/v1/delivery/searchDispatch?status=${status}&from=${context.getters.getGlobalDateRange.from}&to=${context.getters.getGlobalDateRange.to}&dateColumn=${date}&invoiceNumber=${invoice}`)
                .then((response) => response.data)
                .catch(error => errorHandler.tomcatError(error))
        },
        getDispatchInfo(context, id) {
            return window.open(`${synergy}delivery/getDispatchInfo/${id}`)
        },
        sendInv(context, payload) {
            const url = `${window.synergy3}api/v1/delivery/sendToDispatch`
            return context.dispatch('sendRequest',{url,payload})
        }
    },
}
