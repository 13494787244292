<template>
  <div>
    <!-- Button to trigger modal -->
    <button
        type="button"
        :id="id + '-modalButton'"
        class="btn btn-primary"
        data-bs-toggle="modal"
        :data-bs-target="`#${id}-customModal`"
        style="display: none"
    ></button>

    <div
        class="modal fade"
        :id="id + '-customModal'"
        tabindex="-1"
        :aria-labelledby="id + '-customModalLabel'"
        aria-hidden="true"
    >
      <div :class="`modal-dialog modal-${size} modal-dialog-scrollable`">
        <!-- Non-form modal -->
        <div class="modal-content" v-if="!isForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5" :id="id + '-customModalLabel'">{{ title }}</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <slot />
          </div>
          <div v-if="$slots.footer || hasDefaultFooter" class="modal-footer">
            <slot name="footer">
              <!-- Default close button for non-form modal -->
              <button
                  type="button"
                  @click="closeModal"
                  :id="id + '-closeModal'"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
              >
                Close
              </button>
            </slot>
          </div>
        </div>

        <!-- Form modal -->
        <div class="modal-content" v-else>
          <div class="modal-header">
            <h1 class="modal-title fs-5" :id="id + '-customFormModalLabel'">{{ title }}</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form @submit.prevent="submitForm">
            <div class="modal-body">
              <slot />
            </div>
            <div v-if="$slots.footer || hasDefaultFooter" class="modal-footer">
              <slot name="footer">
                <!-- Default footer buttons for form -->
                <button type="submit" class="btn btn-primary">Submit</button>
                <button
                    type="button"
                    @click="closeModal"
                    :id="id + '-closeFormModal'"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                >
                  Close
                </button>
              </slot>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as generateUUID } from "uuid"; // Ensure UUID library is installed

export default {
  name: "CustomModal",
  created() {
    this.id = `modal-` + generateUUID(); // Generate unique ID for each modal
  },
  data() {
    return {
      id: null, // Unique ID for this modal instance
    };
  },
  props: {
    isForm: {
      type: Boolean,
      required: false,
      default: false, // Whether it's a form modal
    },
    title: {
      type: String,
      required: true, // Modal title
    },
    size: {
      type: String,
      required: false,
      default: "xl", // Modal size (default is 'xl')
    },
    hasDefaultFooter: {
      type: Boolean,
      required: false,
      default: true, // Whether to show default footer with buttons
    },
  },
  methods: {
    // Open the modal by triggering its button click
    openModal() {
      const modalButton = document.getElementById(this.id + '-modalButton');
      modalButton.click(); // Trigger the modal open action
      this.$nextTick(() => {
        const modal = document.getElementById(this.id + '-customModal');
        const firstFocusable = modal.querySelector("input, button, select, textarea");
        firstFocusable && firstFocusable.focus(); // Focus on the first input field
      });
      this.$emit("open"); // Emit an 'open' event
    },

    // Close the modal by simulating the button click
    closeModal() {
      const closeButton = this.isForm
          ? document.getElementById(this.id + '-closeFormModal')
          : document.getElementById(this.id + '-closeModal');
      closeButton.click(); // Trigger the close action
      this.$emit("close"); // Emit a 'close' event
    },

    // Submit the form and close the modal
    submitForm() {
      this.closeModal(); // Close the modal after form submission
      this.$emit("submit"); // Emit a 'submit' event
    },
  },
};
</script>

<style scoped>
/* Add any custom modal styling here */
</style>
